import React from "react";
import { Container, Paragraph, Title } from "./style";
import { FC } from "react";
import Button from "@studytools/core/ui/components/Button";
import { Wrapper } from "../common/style";
import styled from "styled-components";

const TeamBehind: FC = () => {
  return (
    <Wrapper id="learning">
      <Container>
        <Title color="primary" variant="h4">
          The Team Behind Ako Maps
        </Title>
        <Paragraph variant="body1">
          Ako Maps is a proud product of Cognitive Performance Labs, a Kiwi company founded in 2018 and based in
          Auckland, New Zealand.
        </Paragraph>
        <Paragraph variant="body1">
          Dr Kerry Spackman, our CEO and co-founder, was inspired to develop Ako Maps from his experiences as a maths
          teacher and from studying maths at the University of Auckland. At the time, he mapped out every concept he
          learned on sheets of brown paper – so big they covered the entire kitchen table! Kerry won the Senior Prize in
          Applied Mathematics, and years later this study technique was modernised as Ako Maps.
        </Paragraph>
        <Center>
          <Button fullWidth={false} onClick={() => (window.location.href = "/the-team")}>
            Meet the Team
          </Button>
        </Center>
      </Container>
    </Wrapper>
  );
};

const Center = styled.div({
  display: "flex",
  justifyContent: "center",
});

export default TeamBehind;
