import React, { FC } from "react";
import { WrapperColored } from "../global";
import { ContainerColored, Paragraph, Title } from "./style";

const NameOrigin: FC = () => {
  return (
    <WrapperColored id="integrated">
      <ContainerColored>
        <Title variant="h4">The origin of the name ‘Ako Maps’</Title>
        <Paragraph variant="body1">
          ‘Ako’ is the te reo Māori word for teaching and learning; it
          recognises the knowledge that teachers and students share during the
          learning journey.
        </Paragraph>
      </ContainerColored>
    </WrapperColored>
  );
};

export default NameOrigin;
