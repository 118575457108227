import React, { FC } from "react";
import { WrapperColoredPadded } from "../common/style";
import { ContainerColored, Title } from "./style";
import Video from "../common/layout/video";

const Integrated: FC = () => {
  return (
    <WrapperColoredPadded id="integrated">
      <ContainerColored>
        <Title variant="h4">
          Ako Maps is an integrated set of tools for learning ideas in
          <strong> context</strong>
        </Title>
        <Video title="What is Ako Maps" src="https://player.vimeo.com/video/573775915" />
      </ContainerColored>
    </WrapperColoredPadded>
  );
};

export default Integrated;
